// Here you can add other styles

.company-logo {
  background: no-repeat left url(../assets/images/data-storage-logo.svg);
  background-size: contain;
  text-indent: -99999px;
  display: inline-block;
  width: 250px;
  height: 48px;
  //margin: 3px 0px;
}

.company-logo2 {
  background: no-repeat left url(../assets/images/flagship-logo.png);
  background-size: contain;
  text-indent: -99999px;
  display: inline-block;
  width: 194px;
  height: 80px;
  //margin: 3px 0px;
}


.bg-dsc {
  background-color: #01649d !important;
    //background-color: var(--cui-primary, #01649d) !important;

  a {
    color: #fff;
    padding: 0 0.5rem;
  }
}


.portal-name {
  //font-size: 1.5rem;
  font-weight: bolder;
  color: #01649d !important;
}

.logon-info {
  text-align: center !important;
}
.logon-header {
  margin-bottom: 2rem;
}

.sidebar-brand {
  background-color: #fff !important;
}


// Assessment form styles


#progressbar {
    position: absolute;
    left: 15px;
    overflow: hidden;
    color: #068CC2
}

#progressbar li {
    list-style-type: none;
    font-size: 8px;
    font-weight: 400;
    margin-bottom: 36px
}

//#progressbar li:nth-child(3) {
//    margin-bottom: 88px
//}

#progressbar .step0:before {
    content: "";
    color: #fff
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    font-size: 20px;
    background: #fff;
    border: 2px solid #068CC2;
    border-radius: 50%;
    margin: auto
}

//#progressbar li:last-child:before {
//    width: 40px;
//    height: 40px
//}

//Progress bar line
#progressbar li:after {
    content: '';
    width: 4px;
    height: 4rem;
    background: #BDBDBD;
    position: absolute;
    left: 58px;
    top: 15px;
    z-index: -1
}

//#progressbar li:last-child:after {
//    top: 147px;
//    height: 132px
//}
//
//#progressbar li:nth-child(3):after {
//    top: 81px
//}

#progressbar li:nth-child(2):after {
    top: 0px
}

#progressbar li:first-child:after {
    position: absolute;
    top: -81px
}

#progressbar li.active:after {
    background: #068CC2
}

#progressbar li.active:before {
    background: #068CC2;
    font-family: FontAwesome;
    content: "1"
}

//#progressbar li.completed:after {
//    background: #97afc2
//}
//
//#progressbar li.completed:before {
//    background: #97AFC2;
//    font-family: FontAwesome;
//    content: "1"
//}

.wizard-steps-progress {
    margin-left: 140px;
    z-index: 0;
    border: none;
    border-right: 1px solid #F5F5F5
}

.wizard-steps-navigation {
  border: none;
  border-right: 1px solid #F5F5F5
}

//.wizard-steps-navigation .progress-bullet p {
//  font-size: 8px;
//  font-weight: 400;
//  margin-bottom: 36px
//}
//
//.wizard-steps-navigation .progress-bullet p {
//  width: 40px;
//  height: 40px;
//  line-height: 40px;
//  display: block;
//  font-size: 20px;
//  background: #fff;
//  border: 2px solid #068CC2;
//  border-radius: 50%;
//  margin: auto
//}
//
//.wizard-steps-navigation .progress-bullet p:after {
//  content: '1';
//  width: 4px;
//  height: 4rem;
//  //background: #BDBDBD;
//  position: absolute;
//  left: 3rem;
//  top: 1rem;
//  //z-index: -1
//}


.wizard-steps-navigation .progress-bullet p {
  font-weight: 400;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  font-size: 20px;
  background: #fff;
  border: 2px solid #068CC2;
  color: #068CC2;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  font-weight: bold;

}


.wizard-steps-navigation .progress-bullet p.active {
  background: #068CC2;
  color: #fff;
}
.wizard-steps-navigation .progress-bullet p.completed {
  background: #E6F3F9;
  //color: #fff;
}
.wizard-steps-navigation .step-title {
  padding: 0.5rem 0;
}

.row-margin-reset {
  margin-top: 0;
}

.row-full-margin-reset {
  margin: 0;
}

.dsc-color {
  color: #068CC2;
}

.refresh {
  cursor: pointer;
}

.wizard-step-table {
  max-height: 400px;
  overflow-y: auto;
  .row {
    width: 100%;
  }
}

// Case View - Comments Chat

.comments-block {
  height: 516px;
  overflow-y: auto;
}

.comment-sent {
  p {
    background: #f5f5f5 none repeat scroll 0 0;
    border-radius: 4px;
    font-size: 14px;
    margin: 0; color:#000;
    padding: 5px 10px 5px 12px;
    width:100%;
  }
}

.comment-received {
  p {
    background: #d0d0d0 none repeat scroll 0 0;
    border-radius: 4px;
    font-size: 14px;
    margin: 0; color:#000;
    padding: 5px 10px 5px 12px;
    width:100%;
  }
  .comment-footer {
    float: right;
  }
}

.comment-footer {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
  .comment-author {
    font-weight: bold;
    color: #0f0f0f;
  }
}

.comment-button {
  padding: 0.575rem 0.75rem;
}

.comment-area {
  border-top: 1px solid #c4c4c4;
  textarea {
    border: none;
    resize: none;
    background-color: #f9f9f9;
  }
  label {
    font-size: 0.9rem;
  }
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
  img {
    max-width:100%;
  }
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 0.9rem;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 0.8rem;
  margin: 8px 0 0;
}

